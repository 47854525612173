<template>
  <div
    v-if="dialog"
    class="fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center bg-zinc-300 bg-opacity-70"
  >
    <div class="w-full max-w-md p-10 bg-white shadow-xl rounded-2xl">
      <div>
        <div class="mb-5">
          <label class="block mb-2 text-sm" for="NameInput"
            >預約姓名<span class="ml-1 text-sm text-primary_dark"
              >＊</span
            ></label
          >
          <input
            v-model="form_data.name"
            id="NameInput"
            name="name"
            type="text"
            placeholder="請輸入您的姓名"
            class="block w-full px-4 py-4 border border-zinc-300 rounded-2xl"
          />
          <p
            v-show="GetError('name')"
            class="mt-2 text-sm font-medium text-red-700"
          >
            請輸入您的姓名
          </p>
        </div>
        <div class="mb-5">
          <label class="block mb-2 text-sm" for="PhoneInput"
            >手機號碼<span class="ml-1 text-sm text-primary_dark"
              >＊</span
            ></label
          >
          <input
            v-model="form_data.phone"
            id="PhoneInput"
            name="phone"
            type="tel"
            placeholder="請輸入您的手機號碼"
            class="block w-full px-4 py-4 border border-zinc-300 rounded-2xl"
          />
          <p
            v-show="GetError('phone')"
            class="mt-2 text-sm font-medium text-red-700"
          >
            請輸入您的手機號碼
          </p>
        </div>
        <div class="mb-10">
          <label class="block mb-2 text-sm" for="Comment">備註留言</label>
          <textarea
            v-model="form_data.comment"
            id="Comment"
            name="comment"
            placeholder="備註留言"
            class="border border-zinc-300 w-full block rounded-2xl px-4 py-4 min-h-[150px]"
          ></textarea>
        </div>
        <div class="w-full">
          <button
            @click="Validate"
            class="block w-full py-4 font-medium text-white transition-colors duration-300 rounded-2xl bg-primary_light hover:bg-primary_dark"
          >
            預約詢價
          </button>
          <button
            @click="Close"
            class="block w-full py-4 font-medium transition-colors duration-200 hover:bg-primary_light rounded-2xl hover:bg-opacity-10 text-primary_light"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendSimpleContact } from '@/api/contact';
import { validPhone } from '@/common/validate';
export default {
  name: 'MainDialog',
  props: {
    dialog: {
      require: true,
      type: Boolean,
    },
    type: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      form_data: {
        name: '',
        phone: '',
        comment: '',
      },
      errors: [],
    };
  },
  methods: {
    Close() {
      this.$emit('close');
      this.errors = [];
      this.form_data.name = '';
      this.form_data.phone = '';
      this.form_data.comment = '';
    },
    GetError(val) {
      return this.errors.indexOf(val) != -1;
    },
    Validate() {
      this.errors = [];
      this.form_data.name == '' ? this.errors.push('name') : '';
      !validPhone(this.form_data.phone) ? this.errors.push('phone') : '';
      if (this.errors.length <= 0) {
        this.SendForm();
      }
    },
    SendForm() {
      let tmp_data = Object.assign({}, this.form_data);
      tmp_data.type = this.type == 'buy' ? '新車詢價' : '賣車詢價';
      sendSimpleContact(tmp_data).then((res) => {
        console.log(res);
        this.Close();
        this.$store.commit('SetDialog', {
          status: true,
          content: '您好，訊息已送出！我們將會盡快與您聯絡！',
        });
      });
    },
  },
};
</script>
