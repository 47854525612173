<template>
  <div id="app" class="flex flex-col min-h-screen">
    <transition name="fade">
      <MainLoading />
    </transition>
    <transition name="fade">
      <MainDialog />
    </transition>
    <!-- <MainHeader /> -->
    <router-view class="flex-1" />

    <p class="fixed text-sm bottom-2 right-4">
      ©2023 Donchedi All Rights Reserved
    </p>
  </div>
</template>

<style src="@/assets/css/app.css"></style>

<script>
// import MainHeader from '@/components/MainHeader.vue';
import MainLoading from '@/components/MainLoading.vue';
import MainDialog from '@/components/MainDialog.vue';
export default {
  name: 'App',
  components: {
    // MainHeader,
    MainLoading,
    MainDialog,
  },
};
</script>
