import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    main_dialog: {
      status: false,
      content: '',
    },
    loading: 0,
    body_lock: 0,
  },
  getters: {},
  mutations: {
    SetBodyLock(state, action) {
      state.body_lock + action < 0
        ? (state.body_lock = 0)
        : (state.body_lock += action);
    },
    SetLoading(state, action) {
      if (action == 1) {
        state.loading += 1;
      } else {
        state.loading -= 1;
      }
    },
    SetDialog(state, { status, content }) {
      state.main_dialog.status = status;
      state.main_dialog.content = content;
    },
    SetStateData(state, { key, val }) {
      state[key] = val;
    },
  },
  actions: {},
  modules: {},
});
