<template>
  <main
    class="relative z-10 flex items-center justify-center w-full min-h-screen"
  >
    <FormDialog
      :dialog="form_dialog"
      :type="form_type"
      @close="form_dialog = false"
    />
    <div class="w-full max-w-screen-sm">
      <h1 class="mb-10 font-bold text-center text-7xl text-primary_light">
        懂車帝
      </h1>
      <div
        class="flex flex-wrap items-center justify-center px-5 md:px-0 md:flex-nowrap"
      >
        <button
          @click="OpenFormDialog('buy')"
          class="block w-full py-5 mb-5 text-lg font-bold text-white transition-colors duration-200 md:w-1/2 md:mb-0 hover:bg-primary_dark md:mr-2 bg-primary_light rounded-xl"
        >
          我要買新車
        </button>
        <button
          @click="OpenFormDialog('sell')"
          class="block w-full py-5 text-lg font-bold transition-colors duration-200 bg-transparent border md:w-1/2 hover:bg-primary_light hover:bg-opacity-20 md:ml-2 border-primary_light rounded-xl text-primary_light"
        >
          我要賣車
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import FormDialog from '@/components/FormDialog.vue';
export default {
  name: 'HomeView',
  components: {
    FormDialog,
  },
  data() {
    return {
      form_type: 'sell',
      form_dialog: false,
    };
  },
  methods: {
    CallPhone() {
      window.open('tel://04-2471-9799');
    },
    OpenFormDialog(type) {
      this.form_type = type;
      this.form_dialog = true;
    },
  },
  mounted() {
    window.prerenderReady = true;
    window.dataLayer.push({
      event: 'page_view',
      page_title: '新車詢價',
    });
  },
};
</script>
