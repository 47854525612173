import { post_form } from '@/common/request';

//聯絡我們
export function sendContact(data) {
  let contact_form = new FormData();
  contact_form.append('form_data', JSON.stringify(data));
  return post_form('contact/send', contact_form);
}

export function sendSell(data) {
  let contact_form = new FormData();
  contact_form.append('form_data', JSON.stringify(data));
  return post_form('contact/sell', contact_form);
}

export function sendSimpleContact(data) {
  let contact_form = new FormData();
  contact_form.append('form_data', JSON.stringify(data));
  return post_form('contact/simple_contact', contact_form);
}
