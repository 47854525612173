<template>
  <div
    v-if="dialog"
    class="fixed z-40 top-0 left-0 right-0 bottom-0 bg-white bg-opacity-70 flex items-center justify-center"
  >
    <div
      class="max-w-md w-full bg-white p-10 rounded-2xl shadow-xl text-center"
    >
      <p class="text-zinc-500 mb-5">{{ content }}</p>
      <button
        @click="Close"
        class="px-5 py-2 rounded-lg md:text-base text-sm bg-primary_light hover:bg-primary_dark transition-colors duration-300 font-bold text-white"
      >
        關閉
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainDialog',
  methods: {
    Close() {
      this.$store.commit('SetDialog', { status: false, content: this.content });
    },
  },
  computed: {
    dialog() {
      return this.$store.state.main_dialog.status;
    },
    content() {
      return this.$store.state.main_dialog.content;
    },
  },
};
</script>
