import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '*',
    redirect: '/',
  },
  // {
  //   path: '/form',
  //   name: 'form',
  //   component: () => import('../views/FormView.vue'),
  // },
  // {
  //   path: '/sell',
  //   name: 'sell',
  //   component: () => import('../views/SellView.vue'),
  // },
  // {
  //   path: '/sell_form',
  //   name: 'sell_form',
  //   component: () => import('../views/SellFormView.vue'),
  // },
  // {
  //   path: '/privacy',
  //   name: 'privacy',
  //   component: () => import('../views/PrivacyView.vue'),
  // },
  // {
  //   path: '/disclaimer',
  //   name: 'disclaimer',
  //   component: () => import('../views/DisclaimerView.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 0 },
      };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
